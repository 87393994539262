<template>
  <div>
    <img v-if="imgList.img" class="tui-img" :src="imgList.img" alt="">
    <!-- 搜索 -->
    <kind-choose-view :show-school="false" :show-kc="false" :show-zy="false" :show-level="false" :type="null"
      :if-img="false" @isOk="isOk" />
    <div class="bigBox">
      <div class="teacherWrap">
        <div v-for="(item, index) in teacherList" :key="index" class="teacherItem" @click="goDetail(item)">
          <el-image class="itemImg" :src="item.terImg" fit="cover" />
          <div class="itemContentWrap">
            <div class="nameWrap">
              <div class="teacherName">{{ item.isRecommend == 1 ? item.userName : item.nickname }}</div>
              <div class="course">{{ item.jobTitle }}</div>
            </div>
            <div class="kindList">
              <template v-if="item.kindList && item.kindList.kindName.length > 0">
                <span v-for="(items, indexs) in item.kindList.kindName" :key="indexs" class="kind_item">
                  {{ items }}
                </span></template>
            </div>
            <div class="line"></div>
            <div class="introduction">讲师简介</div>
            <div class="introduce fireElp" v-html="ImgSizeLimit(item.profile)" />
            <div class="itemBtn">进入主页</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination class="pagination partner-pagination"
      style="margin-top: 6px !important; margin-bottom: 30px !important" :current-page="page.pageNum" prev-text="上一页"
      next-text="下一页" :page-size="page.pageSize" layout="prev, pager, next" :total="page.total"
      @current-change="handleCurrentChange" />
  </div>
</template>

<script>
import kindChooseView from '@/components/kindChooseViewp.vue'
import { getSurfaceList } from '@/api/home'
import { TeacherClient } from '@/api/teacherClient.js'
import { getDomain } from '@/api/cookies'
const teacherClient = new TeacherClient()
export default {
  components: {
    kindChooseView
  },
  data() {
    return {
      teacherList: [],
      page: {
        pageSize: 8,
        pageNum: 1,
        total: 0
      },
      searchVal: {},
      imgList: {},
      domain: null
    }
  },
  created() {
    this.domain = getDomain()
    this.getTeacherList()
    getSurfaceList(5, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0 && res.msg.length > 0) {
        this.imgList = res.msg[0]
      } else {
        this.imgList = {}
      }
    })
  },
  methods: {
    /* 点击查看详情 */
    goDetail(item) {
      this.$router.push({
        path: '/faculty/teacherDetail',
        query: {
          id: item.id
        }
      })
    },
    getTeacher(val) {
      const params = {
        lectureId: val.id
      }
      teacherClient.getPcById(params).then((res) => {
        this.kcList = res.data.kcList.map((item) => {
          return item.kcName
        })
        this.$set(val, 'kindList', res.data)
      })
    },
    isOk(val) {
      this.searchVal = val
      this.getTeacherList()
    },
    /* 师资力量列表 */
    getTeacherList() {
      const params = {
        ...this.page,
        pKindId: this.searchVal.school,
        kindId: this.searchVal.kind
      }
      delete params.total
      teacherClient.selectPCPageList(params).then((res) => {
        res.rows.forEach(async (e) => {
          await this.getTeacher(e)
        })
        console.log(res.rows)
        this.teacherList = res.rows
        this.$forceUpdate()
        this.page.total = res.total
      })
    },
    /* 每页条数 */
    handleSizeChange(val) {
      this.page.pageSize = val
      this.pageNum = 1
      this.getTeacherList()
    },
    /* 当前页码 */
    handleCurrentChange(val) {
      this.page.pageNum = val
      this.getTeacherList()
    }
  }
}
</script>

<style lang="less" scoped>
.bannerWrap {
  width: 100%;
  height: 360px;
}

.bigBox {
  width: 1200px;
  margin: auto;
  margin-top: 40px;

  .teacherWrap {
    display: flex;
    flex-wrap: wrap;

    .teacherItem {
      width: 584px;
      height: 300px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      padding: 20px;
      margin-right: 32px;
      margin-bottom: 24px;
      display: flex;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .itemImg {
        width: 195px;
        height: 260px;
        margin-right: 20px;
      }

      .itemContentWrap {
        position: relative;
        flex: 1;
        padding: 10px 20px 0 0;

        .nameWrap {
          flex: 1;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          // height: 62px;
          margin-bottom: 16px;

          .teacherName {
            height: 26px;
            font-size: 20px;
            line-height: 26px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            margin-right: 8px;
          }

          .course {
            max-width: 150px;
            height: 19px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }

        .kindList {
          flex-wrap: wrap;
          height: 55px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #0271e8;

          .kind_item {
            height: 19px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            margin-right: 10px;
            color: #0271e8;
          }
        }

        .introduce {
          width: 329px;
          height: 85px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 21px;
          margin-bottom: 19px;
          overflow: hidden;
          /*  word-break: break-all;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;  */
        }

        .introduction {
          width: 56px;
          height: 19px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-bottom: 10px;
        }

        .itemBtn {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          top: 0;
          right: 0;
          width: 88px;
          height: 28px;
          background: linear-gradient(47deg, #0270e8 0%, #21b4f5 100%);
          border-radius: 18px 18px 18px 18px;
          opacity: 1;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}

.tui-img {
  width: 100%;
}

.line {
  height: 0;
  border-bottom: 1px dotted #EEEEEE;
  margin: 20px 0;
}
</style>
